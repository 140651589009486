if (typeof window !== 'undefined') {
var langType = window.location.pathname.split('/');
var nesto = "hesto";
langType = langType[1];
var prefix;
var link_consulting = "/consulting";
var link_portfolio = "/portfolio/web-design";
var link_blog = "/blog";
var link_contact = "/contact";
var link_web_design = "/web-design";
var link_graphic_design = "/graphic-design";
var link_digital_marketing = "/digital-marketing";
var link_thankyou = "/thankyou";
var link_references = "/references";

if(langType === "bs"){
prefix = "/bs";
link_portfolio = "/portfolio/web-dizajn";
link_consulting = "/konzalting";
link_contact = "/kontakt";
link_web_design = "/web-dizajn";
link_graphic_design = "/graficki-dizajn";
link_digital_marketing = "/digitalni-marketing";
link_thankyou = "/hvala-vam";
link_references = "/reference";
}
else
prefix = "";
}
export {prefix,nesto, link_consulting, link_portfolio, link_blog, link_contact, link_web_design, link_graphic_design, link_digital_marketing, link_thankyou, link_references};