import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {link_thankyou} from '../langPrefix'
import {Link} from 'gatsby'
import 'aos/dist/aos.css';
import {contact_us, contact_text_big, contact_text_small, contact_informations, name_surname, email,
    select_service, message_title, message, send_message, web_design, phone_number, facebook_advertising, google_advertising, seo_optimization, graphic_design, branding_and_consulting} from '../strings'
const ContactModal = () => (
    
<section id="p_contact" className="hero is-fullheight pages">
    <div className="hero-head"></div>
        <div data-aos='fade-zoom-in' className="hero-body is-padding-0">
            <div className="overlay_image"></div>
            <div className="overlay_color"></div>
            <div className="container">        
                <h1 className="title">{contact_us}</h1>
                <div className="columns">
                    <div className="column is-half">
                        <h3 className="subtitle">{contact_text_big}</h3>
                        <p className="text">{contact_text_small}</p>
                        <div className="contact-info">
                            <h2 className="title"><FontAwesomeIcon icon={['fas', 'phone']} aria-hidden="true" /> {contact_informations}</h2>
                            <a href="tel:+38761787346" className="line"><h4>+387 61 787 346</h4></a>
                            <a href="tel:+38761541177" className="line"><h4>+387 61 541 177 - Support</h4></a>
                            <a href="mailto:info@wellpromotion.ba" className="line email"><h4>info@wellpromotion.ba</h4></a>
                            <h4 className="line">Safeta Mujića 2, Sarajevo, 71000</h4>
                        </div>
                    </div>
                  
                    <div className="column is-half">
                        <form name="contact" className="columns contact-form is-multiline" method="POST" action={link_thankyou} data-netlify="true" data-netlify-honeypot="bot-field">
                        <input type="hidden" name="form-name" value="contact" />
                           <div className="field"><input type="text" name="name" id="name" className="column is-half" placeholder={name_surname} required/></div>
                           <div className="field"> <input type="text" name="email" id="email" className="column is-half" placeholder={email} required/></div>
                            <div className="select">
                            <select name="service" id="service" className="column is-half" required>
                                <option>{select_service}</option>
                                <option value="WEB DESIGN">{web_design}</option>
                                <option value="FACEBOOK OGLAŠAVANJE">{facebook_advertising}</option>
                                <option value="GOOGLE OGLAŠAVANJE">{google_advertising}</option>
                                <option value="SEO OPTIMIZACIJA">{seo_optimization}</option>
                                <option value="GRAFIČKI DESIGN">{graphic_design}</option>
                                <option value="BRANDING I CONSULTING">{branding_and_consulting}</option>
                            </select>
                            </div>
                            <div className="field"> <input type="text" name="phone" id="phone" className="column is-half" placeholder={phone_number} required/></div>
                            <textarea id="message" name="message" className="column is-12" placeholder={message} required></textarea>
                            <button type="submit" className="button submit white">{send_message} <span className="icon contact"></span></button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    <div className="hero-foot">
    </div>
</section>
)

//CONTACT FORM CONTENT//
export default ContactModal
