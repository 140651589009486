import React from 'react'
import Logo from './logo'
import ScrollToTop from 'react-scroll-up'
import Link from 'gatsby-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {close} from '../strings'
class ContactHeader extends React.Component {
    render(props){
    var prefix = "";
    function Languages(prefix_string_page){
        if(prefix_string_page === "bs"){
        prefix = "bs";
        }
        return prefix;
    }
        return(
<div id="s_header" className="section single-mobile-header is-fixed is-full-width is-padding-0">
    <ScrollToTop showUnder={10}
            style={{
                zIndex: 99,
                top:0,
                left:0,
                height:'100px',
                background: 'transparent',
                cursor: 'default'
            }}>
    <div className="header-back">

    </div>
    </ScrollToTop>
 
  <div className="container main-header is-flex">
      <div className="logo is-left">
         <Link to={Languages(this.props.prefix_string_page)}><Logo/></Link>
      </div>

      <div className="navbar is-left">

      </div>
 {/*  <Link to={prefix + "/"} className="contact-close is-right"><FontAwesomeIcon icon={['fas', 'times-circle']} aria-hidden="true" /> {close}</Link>*/}
     <a href="javascript: window.history.go(-1)" className="contact-close is-right"><FontAwesomeIcon icon={['fas', 'times-circle']} aria-hidden="true" /> {close}</a>
    
  </div>
</div>
)
        }
    }

//SINGLE PAGE HEADER//
export default ContactHeader
